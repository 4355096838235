import BaseService from "./base.service";

class MyteamService {
    myteam(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('myteam', page, params);
    }
	get(id) {
        return BaseService.apiGetRequest('myteam/'+id);
    }
    delete(myteam) {
        return BaseService.apiPostRequest('myteam/delete/', myteam);
    }    
    add(myteam) {
        return BaseService.apiPostRequest('myteam/add', myteam);
    }
    update(id, myteam) {
        return BaseService.apiPostRequest('myteam/'+id, myteam);
    }
}
export default new MyteamService();