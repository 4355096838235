<template>
  <list-content
      v-bind:rows-data="tableData"
      v-bind:columns="tableColumns"
      v-bind:add-label="pageContent.add_label"
      v-bind:title="pageContent.title"
      v-bind:new-uri="pageContent.newUri"      
      v-bind:edit-uri="pageContent.editUri"
      v-bind:meta="meta"
      v-bind:show-message="showMessage"
      v-bind:message="message"
      @handlePageChange="handleCurrentChange"      
      @handleSizeChange="handleSizeChange"
      @handleSearchData="performSearch"
      @handleDeleteData="handleDelete"
  ></list-content>
</template>
<script>

import MyteamService from "@/api/myteam.service";
import ListContent from "@/components/ListContent"

export default {
  name: "My Team",
  components: {ListContent},
  data() {
    return {
      tableColumns: [
         {'key':'image', 'label':'', 'sortable':false, 'is_image': true, 'width':100},
          {'key':'fname', 'label':'First Name', 'sortable':true, 'is_image': false, 'width':200},
          {'key':'lname', 'label':'Last Name', 'sortable':true, 'is_image': false, 'width':''},
      ],
      pageContent: {
        'add_label': 'Add My Team',
        'title': 'My Teams',
        'newUri': '/myteam/add',
        'editUri':'/myteam/edit'
      },
      tableData: [],
      page: 1,
      search: '',
      meta: Object,
      showMessage: false,
      message: {message:'', messageType:'success', effect:'dark'}
    }
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    async getTableData() {
      return MyteamService.myteam(this.page, this.search).then(response => {
        this.meta = response.data.meta;
        this.tableData = response.data.data;
      });
    },
    performSearch(search) {
      this.search = search.name;
      this.getTableData();
    },
    handleDelete(data) {
      let deleteRow = data.row;
      this.showMessage = false;
      if (data.indexNo >= 0) {
        return MyteamService.delete(deleteRow).then(response => {
          if(response.status === 201) {
            this.message.message = "Blog Article deleted successfully";
            this.message.messageType = "success";
            this.message.effect = "dark";
            this.showMessage = true;
            this.getTableData();
          }
        });
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getTableData();
    },
    handleSizeChange(val){
      this.getTableData();
    }

  }
}
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>
</style>